<template>
    <div v-if="user" class="profile__detail">
        <div class="list">
            <label class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        Push-Benachrichtigungen
                    </div>
                </div>
                <div class="toggle">
                    <input v-model="receivePushes" type="checkbox">
                    <span class="toggle__button"></span>
                </div>
            </label>
            <label class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        Personalisierte Angebote
                    </div>
                </div>
                <div class="toggle">
                    <input v-model="personalisedOffers" type="checkbox">
                    <span class="toggle__button"></span>
                </div>
            </label>
            <label class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        E-Mail
                    </div>
                </div>
                <div class="toggle">
                    <input v-model="receiveEmails" type="checkbox">
                    <span class="toggle__button"></span>
                </div>
            </label>
            <label class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        Workout-Erinnerungen
                    </div>
                </div>
                <div class="toggle">
                    <input v-model="workoutReminders" type="checkbox">
                    <span class="toggle__button"></span>
                </div>
            </label>
        </div>
        <v-btn class="button button--primary" @click="saveSettings">Übernehmen</v-btn>
    </div>
</template>
<script>

import firebase from 'firebase';

export default {
    name: 'SettingsNotifications',
    data() {
        return {
            user: null,
            userInformation: null,
            workoutReminders: true,
            personalisedOffers: true,
            receiveEmails: true,
            receivePushes: true,
        }
    },
    methods: {
        init(){
            this.user = firebase.auth().currentUser;

            this.$store.dispatch('fetchUserInformation').then( (res) => {
                if (res.length != 0) {
                    this.userInformation = res[0];
                    if (this.userInformation.receivePushes != null) {
                        this.workoutReminders = this.userInformation.workoutReminders;
                        this.personalisedOffers = this.userInformation.personalisedOffers;
                        this.receiveEmails = this.userInformation.receiveEmails;
                        this.receivePushes = this.userInformation.receivePushes;
                    }
                }
            });
        },
        saveSettings() {
            if (this.userInformation == null) {
                this.userInformation = {}
            }
            this.userInformation.receiveEmails = this.receiveEmails;
            this.userInformation.receivePushes = this.receivePushes;
            this.userInformation.personalisedOffers = this.personalisedOffers;
            this.userInformation.workoutReminders = this.workoutReminders;
            this.$store.dispatch('saveUserInformation', this.userInformation);
            this.$router.go(-1);
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style lang="scss">
</style>